<template>
  <div class="mt-4">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Proyek</label>
        <vs-input class="w-full" :value="initData.spk.nama_proyek" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">No. SPK</label>
        <vs-input class="w-full" :value="initData.spk.no_spk" readonly/>
      </div>
      <div class="vx-col w-full" :class="initData.spk.jenis_spk === 'NON UNIT' ? 'sm:w-6/12' : 'sm:w-3/12'">
        <label class="ml-1 text-xs">Jenis SPK</label>
        <vs-input class="w-full" :value="initData.spk.jenis_spk" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full" v-if="initData.spk.jenis_spk !== 'NON UNIT'">
        <label class="ml-1 text-xs">No. Unit</label>
        <vs-input class="w-full" :value="initData.pekerjaan.no_unit" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Kode Rekanan</label>
        <vs-input class="w-full" :value="initData.spk.rekanan.kode" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Nama Rekanan</label>
        <vs-input class="w-full" :value="initData.spk.rekanan.nama" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Jenis Rekanan</label>
        <vs-input class="w-full" :value="initData.spk.rekanan.nama_jenis_rekanan" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Telp Rekanan</label>
        <vs-input class="w-full" :value="initData.spk.rekanan.telp" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/1 w-full">
        <label class="ml-1 text-xs">Alamat Rekanan</label>
        <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" :value="initData.spk.rekanan.alamat" readonly/>
      </div>
    </div>
    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full">
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabGeneral',
  props: ['isActive', 'initData'],
  components: {},
  data () {
    return {}
  },
  methods: {
    next () {
      const currentActiveTab = this.$store.state.approvals.spkProgressApproval.activeTab
      this.$store.commit('approvals/spkProgressApproval/SET_ACTIVE_TAB', currentActiveTab + 1)
    }
  }
}
</script>
